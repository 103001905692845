<script lang="ts">
	import ProductActionTag from './ProductActionTag.svelte';
	import ProductAvailLocation from './ProductAvailLocation.svelte';
	import { cartStorage, type ICartLine, type ICartStorage } from '../../stores/preferences';
	import PriceSelector from '../../components/PriceSelector.svelte';
	import { debug } from '$lib/utils/Console';
	import cn from 'classnames';
	import { getModal } from '../(popups)/_CartAddPopup.svelte';
	import Button from './Button.svelte';
	import Pagination from './Pagination.svelte';
	import {fade} from "svelte/transition";
	import {onMount} from "svelte";
	
	export let article: any;
	export let disabled: boolean = false;
	export let isCartPopup: boolean = false;
	export let isSearch: boolean = false;
	export let pagination: boolean = false;
	export let page: number = 1;
	export let limit: number = 30;
	export let total_count: number = 0;

	function addToCartProduct() {
		let item: any = $cartStorage.new_articles?.lines?.find((item: any) => item.url === article.url);

		if (item === undefined)
			item = $cartStorage.used_articles?.lines?.find((item: any) => item.url === article.url);

		if (item !== undefined) {
			if (item.used) {
				debug('item exists but used is able only one');
				return;
			}

			item.quantity = item.quantity + 1;
			item.total_price = item.quantity * item.unit_price;

			$cartStorage.new_articles.total_price = $cartStorage.new_articles?.lines?.reduce(
				(acc, item) => acc + item?.total_price,
				0
			);
			$cartStorage.used_articles.total_price = $cartStorage.used_articles?.lines?.reduce(
				(acc, item) => acc + item?.total_price,
				0
			);
			$cartStorage.total_price =
				$cartStorage.new_articles.total_price + $cartStorage.used_articles.total_price;
			cartStorage.set($cartStorage);
			getModal(article, 1).open();
		} else {
			debug('item not exists in cart, adding new item to cart');

			let newProduct: ICartLine = {
				url: article.url,
				quantity: 1,
				name: article.name,
				unit_price: article.price,
				total_price: article.price * 1,
				used: article.used,
				img: article.main_image.url_medium,
				action: article.action,
				availability_location: article.availability_location,
				size: article.size,
			};

			let cartActual: ICartStorage = $cartStorage;
			if (article.used) cartActual.used_articles.lines.push(newProduct);
			else cartActual.new_articles.lines.push(newProduct);

			cartActual.new_articles.total_price = cartActual.new_articles?.lines?.reduce(
				(acc, item) => acc + item?.total_price,
				0
			);

			cartActual.used_articles.total_price = cartActual.used_articles?.lines?.reduce(
				(acc, item) => acc + item?.total_price,
				0
			);

			cartActual.total_price =
				cartActual.new_articles.total_price + cartActual.used_articles.total_price;
			cartStorage.set(cartActual);
			getModal(article, 1).open();
		}
	}
</script>

	{#if article}
		<div transition:fade
			class={cn(
				'relative my-5 border border-gray-concrete transition last:mb-0 hover:shadow-lg',
				$$props.class
			)}
		>
			{#if disabled === false}
				<ProductActionTag action={article.action} {article} />
			{/if}
	
			<a
				data-sveltekit-preload-data="tap"
				href="/{article.used ? 'uzbozi' : 'zbozi'}/{article.url}/"
				rel={isSearch ? undefined : 'prefetch'}
				title={article.url}
				class={cn(
					'special-article container mx-auto flex flex-nowrap items-center overflow-hidden px-4 py-6 md:flex-row md:px-7'
				)}
			>
				<div
					class="flex w-1/2 shrink-0 grow-0 items-center justify-center md:!w-auto md:pr-4 lg:pr-12 xs:w-1/3"
				>
				<!--image thumbnail-->
					<a class="relative h-20 w-20 md:h-24 md:w-24"
					   data-sveltekit-preload-data="tap"
					   href="/{article.used ? 'uzbozi' : 'zbozi'}/{article.url}/"
					   rel={isSearch ? undefined : 'prefetch'}
					   title={'Náhled obrázku produktu' + article.name}>
							<img
								class="mx-auto object-contain object-center"
								alt={article.main_image?.alt}
								src={article.main_image?.url_medium}
							/>
					</a>
				</div>
	
				<div
					class="
					flex w-1/2 shrink-0 grow-0 flex-col items-center text-left md:!w-auto md:grow md:items-start lg:flex-grow xs:w-2/3"
				>
				<!--product name-->
					<a class="mb-2 w-full text-base font-bold sm:text-xl" 
					   data-sveltekit-preload-data="tap"
					   href="/{article.used ? 'uzbozi' : 'zbozi'}/{article.url}/"
					   rel={isSearch ? undefined : 'prefetch'}
					   title={'Detail produktu' + article.name}>
						{article.name}
						{#if article.variants_number != null}
							<span class="text-gray-500"> (varianty: {article.variants_number})</span>
						{/if}
					</a>
					{#if disabled !== true}
					<!--product description-->
						{#if article.description}
							<a
									data-sveltekit-preload-data="tap"
									href="/{article.used ? 'uzbozi' : 'zbozi'}/{article.url}/"
									rel={isSearch ? undefined : 'prefetch'}
									title={'Podrobný popis produktu' + article.name}
								class="truncate-overflow truncated-text max-w-[37.5rem] text-gray-oslogray md:max-h-24"
							>
								{article.description}
							</a>
						{/if}
					{:else}
					
						<div class="flex w-full flex-col flex-nowrap items-start sm:flex-row sm:items-center">
							{#if article.producer}
								<div class="mr-2 flex">
									<span class="text-gray-500">Výrobce:&nbsp;</span>
									<a 
									href="/{article.used ? 'uzbozi' : 'zbozi'}/{article.url}/" 
									title={'Zobraz produkt ' +article.name +' od výrobce ' + article.producer}>
									{article.producer}
									</a>
								</div>
							{/if}
	
							{#if !isCartPopup && article.availability_location && article.availability_time}
								<div
									class="flex w-full whitespace-nowrap sm:ml-2 sm:w-auto sm:border-l sm:border-gray-200 sm:pl-4"
								>
									<ProductAvailLocation
										availability_time={article.availability_time}
										availability_location={article.availability_location}
									/>
								</div>
							{/if}
						</div>
					{/if}
				</div>
	
				{#if disabled == true}
					<div class={cn(!isCartPopup && 'pr-5')}>
						<PriceSelector article={article.price} isHeader={true} />
					</div>
				{/if}
			</a>
			{#if disabled != true}
				<div
					class="flex w-full flex-row justify-between bg-gray-alabaster px-4 py-3 sm:px-6 sm:py-4 md:px-7"
				>
					<div class="flex flex-col items-start justify-center text-left lg:flex-row lg:items-center">
						<div>
							<span class="text-gray-500"> Výrobce: </span>
							<a 
									href="/{article.used ? 'uzbozi' : 'zbozi'}/{article.url}/" 
									title={'Zobraz produkt ' +article.name +' od výrobce ' + article.producer}>
									{article.producer}
									</a>
						</div>
	
						{#if article.availability_location && article.availability_time}
							<div
								class="flex w-full flex-row gap-x-1 lg:ml-4 lg:w-auto lg:border-l lg:border-gray-200 lg:pl-4"
							>
								<ProductAvailLocation
									availability_time={article.availability_time}
									availability_location={article.availability_location}
								/>
							</div>
						{/if}
					</div>
	
					{#if article.availability_location !== 'nedostupné'}
						<div
							class="flex flex-col-reverse items-center gap-x-6 text-center sm:flex-row-reverse sm:flex-nowrap md:my-0 md:w-1/2"
						>
							{#if article?.variants_number == undefined && article?.has_variants == false}
								<Button class="mdmax:px-4" onClick={addToCartProduct}>Do košíku</Button>
							{:else}
								<Button class="mdmax:px-4" href="/{article.used ? 'uzbozi' : 'zbozi'}/{article.url}/"
									>Zvolit Variantu</Button
								>
							{/if}
							<PriceSelector {article} />
						</div>
					{/if}
				</div>
			{/if}
		</div>
	{/if}

	{#if pagination}
		<Pagination {page} {limit} {total_count} />
	{/if}
